import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {ProfileService} from './ProfileService';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {appRoutes} from './app.routes';

@Injectable({
  providedIn: 'root',
})
export class ProfileResolver implements Resolve<boolean> {
  constructor(private profileService: ProfileService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const goodOrderRoute = route.data['goodRoute'] || '/order-good';
    const badOrderRoute = route.data['badRoute'] || '/order-bad';

    return this.profileService.fetchProfile().pipe(
      tap((response: any) => {
        const version = response.version;

        // Navigiere zur entsprechenden Route basierend auf der Version
        if (version === 'good') {
          this.router.navigate([goodOrderRoute])

        } else {
          this.router.navigate([badOrderRoute]);
        }
      })
    );

  }
}
