import { Component } from '@angular/core';
import {Truck} from '../../model/truck';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {MatButton} from '@angular/material/button';
import {NgOptimizedImage} from '@angular/common';

@Component({
  selector: 'app-bad-header',
  imports: [
    RouterLink,
    RouterLinkActive,
    MatButton,
  ],
  standalone: true,
  templateUrl: './bad.header.component.html',
  styleUrl: './bad.header.component.css'
})
export class BadHeaderComponent {

}
