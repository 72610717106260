import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {HttpClientModule} from '@angular/common/http';
import {OrderService} from '../../service/order.service';
import {SuccessModalComponent} from '../../order-success-modal/success-modal.component';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {OrderComponent} from '../../order/order.component';

@Component({
  selector: 'app-bad-order',
  standalone: true, // Markiert die Komponente als Standalone
  imports: [

    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    HttpClientModule,
    MatDialogModule,
  ],


  templateUrl: './bad.order.component.html',
  styleUrls: ['./bad.order.component.css'],
})
export class BadOrderComponent extends OrderComponent{

  override submitOrder(): void {
    if (this.selectedTruckId && this.pallets && this.cargo.weight && this.cargo.pallet) {
      const order = {
        truck: { truckId: this.selectedTruckId },
        kilometers: this.pallets,
        cargo: this.cargo,
      };

      this.orderService.createOrder(order).subscribe(
        (response) => {
          console.log('Bestellung erfolgreich:', response);
          this.openSuccessModal(response); // Erfolg wird wie gewohnt behandelt
          this.resetForm();
        },
        (error) => {
          if (error.status === 400) {
            console.warn('BadOrderComponent: Server gibt 400 zurück, behandeln es als Erfolg:', error.error);
            this.openSuccessModal(error.error); // Auch bei 400 das Modal öffnen
            this.resetForm();
          } else {
            console.error('BadOrderComponent: Fehler beim Absenden der Bestellung:', error);
          }
        }
      );
    } else {
      console.error('Alle Felder müssen ausgefüllt sein!');
    }
  }
}
