<header class="app-header">
  <img [src]="'/image/logotcraft.png'" alt="Logo" class="logo">
  <nav>
    <!-- Button für Neue Bestellung -->
    <button mat-raised-button color="primary" routerLink="/order" routerLinkActive="active">
      Neue Bestellung
    </button>

    <!-- Button für Alle Bestellungen -->
    <button mat-raised-button color="accent" routerLink="/orders" routerLinkActive="active">
      Alle Bestellungen
    </button>
  </nav>
</header>
