<h1 mat-dialog-title>Bestellung bearbeiten</h1>
<div mat-dialog-content>
  <form>
    <!-- Kilometer -->
    <mat-form-field appearance="fill">
      <mat-label>Kilometer</mat-label>
      <input
        matInput

        [(ngModel)]="data.kilometers"
        name="kilometers"
        type="number"
        required
        (input)="validateKilometers()"
        min="1"
        max="50000"
        #kilometersInput=ngModel
      />
      <mat-error *ngIf="kilometersInput.invalid && kilometersInput.touched">
        Bitte gib eine Zahl zwischen 1 und 50.000 ein.
      </mat-error>
    </mat-form-field>

    <!-- Gewicht -->
    <mat-form-field appearance="fill">
      <mat-label>Gewicht</mat-label>
      <input
        matInput
        [(ngModel)]="data.cargo.weight"
        name="weight"
        type="number"
        (input)="validateWeight()"
        required
        min="1"
        [max]="maxWeight"
        #weightInput="ngModel"
      />
      <mat-error *ngIf="weightInput.invalid && weightInput.touched">
        Bitte gib eine Zahl zwischen 1 und {{ selectedTruck?.maxWeight }} ein.
      </mat-error>
    </mat-form-field>

    <!-- Paletten -->
    <mat-form-field appearance="fill">
      <mat-label>Paletten</mat-label>
      <input
        matInput
        name="pallet"
        type="number"
        [(ngModel)]="data.cargo.pallet"
        required
        [min]="1"
        [max]="maxPallets"
        (input)="validatePallets()"
        #palletsInput="ngModel"
      />
      <mat-error *ngIf="palletsInput.invalid && palletsInput.touched">
        Bitte gib eine Zahl zwischen 1 und {{ selectedTruck?.maxPallets }} ein.
      </mat-error>
    </mat-form-field>

    <!-- LKW-Auswahl -->
    <mat-form-field appearance="fill">
      <mat-label>Wähle einen LKW</mat-label>
      <mat-select
        [(ngModel)]="data.truck.truckId"
        name="truckId"
        (selectionChange)="onTruckChange($event.value)"
        required
      >
        <mat-option *ngFor="let truck of trucks" [value]="truck.truckId">
          {{ truck.truckName }} (Max: {{ truck.maxWeight }} kg, Paletten: {{ truck.maxPallets }})
        </mat-option>
      </mat-select>
    </mat-form-field>


    <div mat-dialog-actions>
      <button mat-button (click)="cancel()">Abbrechen</button>
      <button mat-raised-button color="primary" (click)="save()">Speichern</button>
    </div>
  </form>
</div>
