import { Component, OnInit } from '@angular/core';
import { OrderService } from '../service/order.service';
import {DecimalPipe, NgForOf} from '@angular/common';
import {MatButton} from '@angular/material/button';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {EditOrderModalComponent} from '../edit-order-modal/edit-order-modal.component';
import {ConfirmDeleteModalComponent} from '../confirm-delete-modal/confirm-delete-modal.component';
import {DeleteSuccessModalComponent} from '../delete-success-modal/delete-success-modal.component';
import {UpdateSuccessModalComponent} from '../update-success-modal/update-success-modal.component';

@Component({
  selector: 'app-order-list',
  standalone: true,
  imports: [
    NgForOf,
    DecimalPipe,
    MatButton,
    MatDialogModule
  ],
  templateUrl: './order-list.component.html',
  styleUrl: './order-list.component.css'
})
export class OrderListComponent implements OnInit {
  orders: any[] = []; // Liste aller Bestellungen

  constructor(private orderService: OrderService,private dialog: MatDialog) {}

  /**
   * Wird beim Initialisieren der Komponente aufgerufen und lädt die Liste der Bestellungen.
   */

  ngOnInit(): void {
    this.loadOrders();
  }

  /**
   * Lädt die Liste aller Bestellungen vom Backend und speichert sie in der Komponente.
   */

  loadOrders(): void {
    this.orderService.getOrders().subscribe(
      (data) => {
        this.orders = data;
        console.log('Geladene Bestellungen:', this.orders);
      },
      (error) => {
        console.error('Fehler beim Laden der Bestellungen:', error);
      }
    );
  }

  /**
   * Löscht eine Bestellung, nachdem eine Bestätigung im Dialog eingeholt wurde.
   *
   * @param id Die ID der zu löschenden Bestellung.
   */

  deleteOrder(id: number): void {
    const dialogRef = this.dialog.open(ConfirmDeleteModalComponent, {
      width: '400px',
      data: { id }
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.orderService.deleteOrder(id).subscribe(
          () => {
            console.log('Bestellung erfolgreich gelöscht');

            this.dialog.open(DeleteSuccessModalComponent, {
              width: '300px'
            });
            this.loadOrders(); // Liste neu laden
          },
          (error) => console.error('Fehler beim Löschen der Bestellung:', error)
        );
      }
    });
  }

  /**
   * Öffnet ein Bearbeitungsmodal mit den Details der ausgewählten Bestellung.
   *
   * @param order Die Bestellung, die bearbeitet werden soll.
   */

  openEditModal(order: any): void {
    const dialogRef = this.dialog.open(EditOrderModalComponent, {
      width: '400px',
      data: { ...order }, // Übergeben einer Kopie der Bestellung
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.updateOrder(result); // Aktualisieren der Bestellung
        this.dialog.open(UpdateSuccessModalComponent, {
          width: '300px'
        });
      }
    });
  }

  /**
   * Aktualisiert eine Bestellung basierend auf den bearbeiteten Daten.
   *
   * @param order Die aktualisierten Bestelldaten.
   */
  //Wurde geändert, damit keine Tabelle mehr nach dem Update geladen wird. Aber in der GUI wird der Wert geändert
  updateOrder(order: any): void {
    this.orderService.updateOrder(order.id, order).subscribe(
      () => {
        console.log('Bestellung erfolgreich aktualisiert');

        // Lokale Aktualisierung der Bestellung in der Liste
        const index = this.orders.findIndex(o => o.id === order.id);
        if (index !== -1) {
          this.orders[index] = { ...order }; // Direkt in der Liste aktualisieren
        }

        // Erfolgsmeldung anzeigen
        this.dialog.open(UpdateSuccessModalComponent, {
          width: '300px'
        });
      },
      (error) => console.error('Fehler beim Aktualisieren der Bestellung:', error)
    );
  }
}
