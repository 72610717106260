import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderService {

  private baseUrl = '/api'; // URL deines Backends

  /**
   * Konstruktor des Services.
   *
   * @param http Der HttpClient für HTTP-Anfragen.
   */

  constructor(private http: HttpClient) {}

  /**
   * Ruft die Liste der verfügbaren Trucks vom Backend ab.
   *
   * @returns Ein Observable mit einer Liste von Trucks.
   */

  getTrucks(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/trucks`);
  }

  /**
   * Erstellt eine neue Bestellung im Backend.
   *
   * @param order Die zu erstellende Bestellung.
   * @returns Ein Observable mit der erstellten Bestellung.
   */

  createOrder(order: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/createOrder`, order);
  }

  /**
   * Ruft die Liste aller Bestellungen vom Backend ab.
   *
   * @returns Ein Observable mit einer Liste von Bestellungen.
   */

  getOrders(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/getAllOrders`);
  }

  /**
   * Löscht eine Bestellung anhand ihrer ID.
   *
   * @param id Die ID der zu löschenden Bestellung.
   * @returns Ein Observable vom Typ `void`, das den Abschluss der Löschung signalisiert.
   */

  deleteOrder(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/deleteOrder/${id}`);
  }

  /**
   * Aktualisiert eine bestehende Bestellung im Backend.
   *
   * @param id Die ID der zu aktualisierenden Bestellung.
   * @param updatedOrder Die aktualisierten Bestelldaten.
   * @returns Ein Observable mit der aktualisierten Bestellung.
   */

  updateOrder(id: number, updatedOrder: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/updateOrder/${id}`, updatedOrder);

  }
}
