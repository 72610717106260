<div class="order-container">
  <h1>Erstelle eine Bestellung</h1>

  <div class="form-row">
    <!-- LKW-Auswahl -->
    <div class="form-group">
      <label for="truck" class="form-label">LKW auswählen:*</label>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Wähle einen Truck Goooood</mat-label>
        <mat-select
          id="truck"
          [(ngModel)]="selectedTruckId"
          name="truckId"
          required
          (selectionChange)="onTruckChange($event.value)"
        >
          <mat-option *ngFor="let truck of trucks" [value]="truck.truckId">
            {{ truck.truckName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Truck-Details -->
    <div class="truck-details">
      <div class="detail-item">
        <p>
          <strong class="label">Maximales Gewicht: </strong>
          <span class="value">{{ selectedTruck?.maxWeight ? selectedTruck.maxWeight + ' kg' : 'Keine Auswahl' }}</span>
        </p>
      </div>
      <div class="detail-item">
        <p>
          <strong class="label">Maximale Paletten: </strong>
          <span
            class="value">{{ selectedTruck?.maxPallets ? selectedTruck.maxPallets + ' Stück' : 'Keine Auswahl' }}</span>
        </p>
      </div>
    </div>
  </div>

  <!-- Kilometer -->
  <div class="form-row">
    <div class="form-group">
      <label for="kilometers" class="form-label">Kilometer eingeben:*</label>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Gib die Kilometer ein</mat-label>
        <input
          matInput
          id="kilometers"
          type="number"
          placeholder="Kilometer"
          [(ngModel)]="pallets"
          name="kilometers"
          required
          min="1"
          max="50000"
          (input)="validateKilometers()"
          #kilometersInput="ngModel"
        />
        <mat-error *ngIf="kilometersInput.invalid && kilometersInput.touched">
          Bitte gib eine Zahl zwischen 1 und 50,000 ein.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="truck-details">
      <div class="detail-item">
        <p>
          <strong class="label">Ab 500km 5% Rabatt</strong>
        </p>
      </div>
      <div class="detail-item">
        <p>
          <strong class="label">Ab 1000km 8% Rabatt </strong>
        </p>
      </div>
    </div>
  </div>

  <div class="form-group-container">

    <div class="form-group">
      <label for="weight" class="form-label">Gesamtgewicht der Paletten (in kg):*</label>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Gib das Gewicht der Ladung ein</mat-label>
        <input
          matInput
          id="weight"
          type="number"
          placeholder="Gewicht in kg"
          [(ngModel)]="cargo.weight"
          name="weight"
          required
          [min]="1"
          [max]="selectedTruck?.maxPallets"
          (input)="validateWeight()"
          #weightInput="ngModel"
        />
        <mat-error *ngIf="weightInput.invalid && weightInput.touched">
          Bitte gib eine Zahl zwischen 1 und {{ selectedTruck?.maxWeight }} ein.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form-group">
      <label for="pallets" class="form-label">Anzahl der Paletten:*</label>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Gib die Anzahl der Paletten ein</mat-label>
        <input
          matInput
          id="pallets"
          type="number"
          placeholder="Palettenanzahl"
          [(ngModel)]="cargo.pallet"
          name="pallet"
          required
          [min]="1"
          [max]="selectedTruck?.maxPallets"
          (input)="validatePallets()"
          #palletsInput="ngModel"
        />
        <mat-error *ngIf="palletsInput.invalid && palletsInput.touched">
          Bitte gib eine Zahl zwischen 1 und {{ selectedTruck?.maxPallets }} ein.
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <!-- Abschicken -->
  <div class="form-actions">
    <button
      mat-raised-button
      color="primary"
      (click)="submitOrder()"
      [disabled]="!selectedTruckId || !pallets || !cargo.weight || !cargo.pallet"
    >
      Bestellung abschicken
    </button>
  </div>
</div>
