import {Component, Inject, OnInit} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA, MatDialogContent, MatDialogTitle, MatDialogActions,
} from '@angular/material/dialog';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'app-edit-order-modal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatDialogContent,
    MatDialogTitle,
    MatDialogActions,
  ],
  templateUrl: './edit-order-modal.component.html',
  styleUrls: ['./edit-order-modal.component.css'],
})
export class EditOrderModalComponent implements OnInit {
  trucks: any[] = []; // Verfügbare Trucks
  selectedTruckId: number | undefined; // ID des neu ausgewählten Trucks
  selectedTruck: any | undefined;
  maxWeight: number = 0; // Dynamisch an den Truck angepasst
  maxPallets: number = 0; // Dynamisch an den Truck angepasst

  constructor(
    public dialogRef: MatDialogRef<EditOrderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, // Enthält die aktuelle Bestellung
    private http: HttpClient // Für Truck-Daten
  ) {
  }

  /**
   * Initialisiert die Komponente, lädt die Trucks und setzt die Auswahl sowie die Validierungen.
   */

  ngOnInit(): void {
    this.loadTrucks();
    this.selectedTruckId = this.data.truck.truckId; // Initialisiere mit aktuellem Truck
    this.updateTruckLimits(this.data.truck);
    this.validateCargo();
  }

  /**
   * Lädt die verfügbaren Trucks vom Backend und speichert sie in der Komponente.
   */

  loadTrucks(): void {
    this.http.get<any[]>('/api/trucks').subscribe(
      (trucks) => {
        this.trucks = trucks;
      },
      (error) => {
        console.error('Fehler beim Laden der Trucks:', error);
      }
    );
  }

  /**
   * Wird beim Wechsel des Trucks ausgelöst. Aktualisiert die Truck-Daten,
   * Truck-Limits und validiert die aktuelle Fracht.
   *
   * @param truckId Die ID des ausgewählten Trucks.
   */

  onTruckChange(truckId: number): void {
    const selectedTruck = this.trucks.find((truck) => truck.truckId === truckId);
    if (selectedTruck) {
      this.data.truck = selectedTruck; // Aktualisiere die Bestellung mit dem neuen Truck
      this.updateTruckLimits(selectedTruck); // Aktualisiere maxWeight und maxPallets
      this.validateCargo();
    }
  }

  /**
   * Validiert die gesamte Fracht basierend auf Gewicht, Paletten und Kilometern.
   */

  validateCargo(): void {
    this.validateKilometers();
    this.validateWeight();
    this.validatePallets();
  }

  /**
   * Aktualisiert die maximalen Werte (Gewicht und Paletten) basierend auf dem Truck.
   *
   * @param truck Der aktuell ausgewählte Truck.
   */

  updateTruckLimits(truck: any): void {
    this.maxWeight = truck.maxWeight;
    this.maxPallets = truck.maxPallets;
  }

  /**
   * Validiert die Kilometeranzahl und stellt sicher, dass sie zwischen 1 und 50.000 liegt.
   * Setzt den Wert auf das Minimum/Maximum, falls außerhalb des gültigen Bereichs.
   */

  public validateKilometers(): void {
    if (this.data.kilometers !== undefined) {
      if (this.data.kilometers < 1) {
        this.data.kilometers = 1; // Setzt den Wert auf das Minimum
      } else if (this.data.kilometers > 50000) {
        this.data.kilometers = 50000; // Setzt den Wert auf das Maximum
      }

      // Aktualisiere das Eingabefeld mit dem korrigierten Wert
      const inputElement = document.getElementById('kilometers') as HTMLInputElement;
      if (inputElement) {
        inputElement.value = this.data.kilometers.toString();
      }
    }
  }

  /**
   * Validiert das Gewicht der Fracht und stellt sicher, dass es zwischen 1 und dem maximalen Gewicht des Trucks liegt.
   */

  validateWeight(): void {
    if (this.data.cargo.weight < 1) {
      this.data.cargo.weight = 1;
    } else if (this.data.cargo.weight > this.data.truck.maxWeight) {
      this.data.cargo.weight = this.data.truck.maxWeight;
    }
  }

  /**
   * Validiert die Anzahl der Paletten und stellt sicher, dass sie zwischen 1 und der maximalen Palettenanzahl des Trucks liegt.
   */

  validatePallets(): void {
    if (this.data.cargo.pallet < 1) {
      this.data.cargo.pallet = 1;
    } else if (this.data.cargo.pallet > this.data.truck.maxPallets) {
      this.data.cargo.pallet = this.data.truck.maxPallets;
    }
  }

  /**
   * Schließt den Dialog ohne Änderungen und gibt `null` zurück.
   */

  cancel(): void {
    this.dialogRef.close(null);
  }

  /**
   * Speichert die Änderungen und schließt den Dialog, wobei die aktualisierten Daten zurückgegeben werden.
   */

  save(): void {
    this.dialogRef.close(this.data); // Gibt die aktualisierten Daten zurück
  }


}
