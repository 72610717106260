import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {MatButton} from '@angular/material/button';

@Component({
  selector: 'app-confirm-delete-modal',
  standalone: true,
  template: `
    <h1 mat-dialog-title>Löschen bestätigen</h1>
    <div mat-dialog-content>
      <p>Möchten Sie diese Bestellung wirklich löschen?</p>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onCancel()">Abbrechen</button>
      <button mat-raised-button color="warn" (click)="onConfirm()">Löschen</button>
    </div>
  `,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButton
  ]
})
export class ConfirmDeleteModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: number }
  ) {}

  /**
   * Schließt den Dialog und gibt `false` zurück, um anzuzeigen, dass der Vorgang abgebrochen wurde.
   */

  onCancel(): void {
    this.dialogRef.close(false); // Rückgabe von "false" bei Abbrechen
  }

  /**
   * Schließt den Dialog und gibt `true` zurück, um anzuzeigen, dass der Vorgang bestätigt wurde.
   */

  onConfirm(): void {
    this.dialogRef.close(true); // Rückgabe von "true" bei Bestätigung
  }
}
