import { Routes } from '@angular/router';
import { OrderListComponent } from './order-list/order-list.component';
import { GoodOrderComponent } from './OrderGoodAndBad/goodOrder/good.order.component';
import { BadOrderComponent } from './OrderGoodAndBad/badOrder/bad.order.component';
import { ProfileResolver } from './ProfileResolver';
import {OrderComponent} from './order/order.component';
import {GoodOrderListComponent} from './ListGoodAndBad/good-order-list/good-order-list.component';
import {BadOrderListComponent} from './ListGoodAndBad/bad-order-list/bad-order-list.component';

export const appRoutes: Routes = [
  {
    path: 'order',
    component: OrderComponent, // 🛠 Platzhalter-Komponente für die Route
    resolve: {profile: ProfileResolver},
    data: {
      goodRoute: 'order-good',
      badRoute: 'order-bad'
    },
  },
  { path: 'order-good', component: GoodOrderComponent },
  { path: 'order-bad', component: BadOrderComponent },

  { path: 'orders',
    component: OrderListComponent,
  resolve:{profile: ProfileResolver},
    data: {
      goodRoute: 'orders-good',
      badRoute: 'orders-bad'
    }
  },
  {path: 'orders-good', component:GoodOrderListComponent},
  {path: 'orders-bad', component:BadOrderListComponent},

  { path: '', redirectTo: '/order', pathMatch: 'full' },
  { path: '**', redirectTo: '/order' },

];
