import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {MatButton} from '@angular/material/button';
import {DecimalPipe} from '@angular/common';


@Component({
  selector: 'app-success-modal',
  template: `
    <h1 mat-dialog-title>Bestellung erfolgreich!</h1>
    <div class="labels">
    <p>
      <strong class="label">ID: </strong>
      <span class="value">{{ data.order.id}}</span>
    </p>

    <p>
      <strong class="label">Preis: </strong>
    <span class="price-column">{{ (data.order.price.price / 100) || 0|  number: '1.2-2' }} €</span>
    </p>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="close()">Schließen</button>
    </div>
  `,
  imports: [
    MatDialogTitle,
    MatButton,
    MatDialogActions,
    DecimalPipe
  ],
  styles: [`
    h1 {
      color: #4caf50;
      text-align: center; /* Zentriert die Überschrift */
    }
    .labels{
      text-align: center;
      font-size:20px ;
    }

    .label {
      font-weight: bold;
    }

  `]
})
export class SuccessModalComponent {
  constructor(
    public dialogRef: MatDialogRef<SuccessModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  /**
   * Schließt den aktuellen Dialog.
   */

  close(): void {
    this.dialogRef.close();
  }
}
