import { Component } from '@angular/core';

@Component({
  selector: 'app-bad-footer',
  standalone: true,
  templateUrl: './bad.footer.component.html',
  styleUrl: './bad.footer.component.css'
})
export class BadFooterComponent {

}
