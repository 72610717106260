import {Component, NgModule, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {GoodHeaderComponent} from './headerGoodAndBad/good-header/good.header.component';
import {GoodFooterComponent} from './footerGoodAndBad/good-footer/good.footer.component';
import {FormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {ProfileService} from './ProfileService';
import {NgIf} from '@angular/common';
import {BadHeaderComponent} from './headerGoodAndBad/bad-header/bad.header.component';
import {BadFooterComponent} from './footerGoodAndBad/bad-footer/bad.footer.component';



@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, GoodHeaderComponent, FormsModule, NgIf, GoodHeaderComponent, BadHeaderComponent, GoodFooterComponent,BadFooterComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  title = 'frontend';
  version: string = 'bad'; // Standardversion

  constructor(private profileService: ProfileService) {}

  // Implementiere die Methode ngOnInit
  ngOnInit(): void {
    this.profileService.fetchProfile().subscribe(response => {
      this.version = response.version;
    });
  }
}

/**
 * Modulkonfiguration für die Angular-Anwendung.
 * Importiert die Material-Module, die für Dialoge und Buttons benötigt werden.
 */

@NgModule({
  imports: [
    MatDialogModule,
    MatButtonModule,
  ],
})
export class AppModule {
}

