<div class="order-list">
  <h1>Liste aller Bestellungen</h1>
  <table>
    <thead>
    <tr>
      <th>ID</th>
      <th>Kilometer</th>
      <th>Gewicht</th>
      <th>Paletten</th>
      <th>LKW</th>
      <th>Maximale Zuladung</th>
      <th>Maximale Palettenanzahl</th>
      <th>Preis</th>
      <th>Aktionen</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let order of orders">
      <td>{{ order.id }}</td>
      <td>{{ order.kilometers }}</td>
      <td>{{ order.cargo.weight }}&nbsp;kg</td>
      <td>{{ order.cargo.pallet }}</td>
      <td>{{ order.truck.truckName }}</td>
      <td>{{ order.truck.maxWeight }}</td>
      <td>{{ order.truck.maxPallets }}</td>
      <td class="price-column">{{ (order.price.price / 100) | number: '1.2-2' }} €</td>
      <td>
        <div class="button-group">
          <button mat-button color="warn" (click)="deleteOrder(order.id)">Löschen</button>
          <button mat-button color="primary" (click)="openEditModal(order)">Bearbeiten</button>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>


