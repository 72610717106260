import { Component, OnInit } from '@angular/core';
import {DecimalPipe, NgForOf} from '@angular/common';
import {MatButton} from '@angular/material/button';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {OrderListComponent} from '../../order-list/order-list.component';

@Component({
  selector: 'app-order-list',
  standalone: true,
  imports: [
    NgForOf,
    DecimalPipe,
    MatButton,
    MatDialogModule
  ],
  templateUrl: './good-order-list.component.html',
  styleUrl: './good-order-list.component.css'
})
export class GoodOrderListComponent extends OrderListComponent {

}
