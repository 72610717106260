import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {HttpClientModule} from '@angular/common/http';
import {OrderService} from '../service/order.service';
import {SuccessModalComponent} from '../order-success-modal/success-modal.component';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';

@Component({
  selector: 'app-order',
  standalone: true, // Markiert die Komponente als Standalone
  imports: [

    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    HttpClientModule,
    MatDialogModule,
  ],


  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css'],
})
export class OrderComponent implements OnInit {
  trucks: any[] = [];
  selectedTruckId: number | undefined;
  selectedTruck: any | undefined;
  pallets: number | undefined;
  cargo: { weight: number; pallet: number} = {weight: 1, pallet: 1};

  // MatDialog im Konstruktor injizieren
  constructor(protected orderService: OrderService, private dialog: MatDialog) {
  }

  /**
   * Initialisiert die Komponente und lädt die Liste der verfügbaren Trucks.
   */

  ngOnInit(): void {
    this.loadTrucks();
  }

  /**
   * Lädt die verfügbaren Trucks aus dem Backend und speichert sie in der Komponente.
   */

  loadTrucks(): void {
    this.orderService.getTrucks().subscribe(
      (data) => {
        this.trucks = data;
        console.log('Geladene Trucks:', this.trucks);
      },
      (error) => {
        console.error('Fehler beim Laden der Trucks:', error);
      }
    );
  }

  /**
   * Wird beim Wechsel des Trucks aufgerufen und speichert den ausgewählten Truck.
   *
   * @param truckId Die ID des ausgewählten Trucks.
   */

  onTruckChange(truckId: number): void {
    this.selectedTruck = this.trucks.find((truck) => truck.truckId === truckId);
  }

  /**
   * Sendet die Bestellung basierend auf den eingegebenen Daten ab.
   * Zeigt bei Erfolg ein Erfolgsmodal an und setzt das Formular zurück.
   */

  submitOrder(): void {
    if (this.selectedTruckId && this.pallets && this.cargo.weight && this.cargo.pallet) {
      const order = {
        truck: {truckId: this.selectedTruckId},
        kilometers: this.pallets,
        cargo: this.cargo,
      };

      this.orderService.createOrder(order).subscribe(
        (response) => {
          console.log('Bestellung erfolgreich:', response);
          this.openSuccessModal(response);
          this.resetForm();

        },
        (error) => {
          console.error('Fehler beim Absenden der Bestellung:', error);
        }
      );
    } else {
      console.error('Alle Felder müssen ausgefüllt sein!');
    }
  }

  /**
   * Öffnet ein Erfolgsmodal mit den Daten der abgeschickten Bestellung.
   *
   * @param order Die abgeschickte Bestellung, die im Modal angezeigt wird.
   */

  openSuccessModal(order:any): void {
    this.dialog.open(SuccessModalComponent, {
      width: '400px',
      data: { order },
    });
  }

  /**
   * Setzt das Formular auf die Standardwerte zurück.
   */

  resetForm(): void {
    this.selectedTruckId = undefined;
    this.selectedTruck = undefined;
    this.pallets = undefined;
    this.cargo = {weight: 0, pallet: 0};
  }

  /**
   * Validiert die Anzahl der Kilometer und stellt sicher, dass sie zwischen 1 und 50.000 liegt.
   * Korrigiert den Wert bei Bedarf und aktualisiert das Eingabefeld.
   */

  public validateKilometers(): void {
    if (this.pallets !== undefined) {
      if (this.pallets < 1) {
        this.pallets = 1; // Setzt den Wert auf das Minimum
      } else if (this.pallets > 50000) {
        this.pallets = 50000; // Setzt den Wert auf das Maximum
      }

      // Aktualisiere das Eingabefeld mit dem korrigierten Wert
      const inputElement = document.getElementById('kilometers') as HTMLInputElement;
      if (inputElement) {
        inputElement.value = this.pallets.toString();
      }
    }
  }

  /**
   * Validiert die Anzahl der Paletten und stellt sicher, dass sie zwischen 1 und der maximalen Palettenanzahl des Trucks liegt.
   * Korrigiert den Wert bei Bedarf und aktualisiert das Eingabefeld.
   */

  public validatePallets(): void {
    if (this.selectedTruck && this.cargo.pallet !== undefined) {
      const maxPallets = this.selectedTruck.maxPallets;

      if (this.cargo.pallet > maxPallets) {
        this.cargo.pallet = maxPallets; // Setzt den Wert auf das Maximum des LKWs
      } else if (this.cargo.pallet < 1) {
        this.cargo.pallet = 1; // Setzt den Wert auf das Minimum
      }

      const inputElement = document.getElementById('pallets') as HTMLInputElement;
      if (inputElement) {

        inputElement.value = this.cargo.pallet.toString();
      }
    }
  }

  /**
   * Validiert das Gewicht der Fracht und stellt sicher, dass es zwischen 1 und dem maximalen Gewicht des Trucks liegt.
   * Korrigiert den Wert bei Bedarf und aktualisiert das Eingabefeld.
   */

  validateWeight(): void {
    if (this.selectedTruck && this.cargo.pallet !== undefined) {
      const maxWeight = this.selectedTruck.maxWeight;

      if (this.cargo.weight > maxWeight) {
        this.cargo.weight = maxWeight; // Setzt den Wert auf das Maximum des LKWs
      } else if (this.cargo.weight < 1) {
        this.cargo.weight = 1; // Setzt den Wert auf das Minimum
      }

      const inputElement = document.getElementById('weight') as HTMLInputElement;
      if (inputElement) {
        inputElement.value = this.cargo.weight.toString();
      }
    }
  }

}
