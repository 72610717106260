<div class="layout">
  <header>
    <app-good-header *ngIf="version === 'good'"></app-good-header>
    <app-bad-header *ngIf="version === 'bad'"></app-bad-header>
  </header>


  <!-- Hauptinhalt -->
  <main class="content">
    <router-outlet></router-outlet>
  </main>

  <footer>
    <app-good-footer *ngIf="version === 'good'"></app-good-footer>
    <app-bad-footer *ngIf="version === 'bad'"></app-bad-footer>
  </footer>
</div>
