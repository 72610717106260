import { Component } from '@angular/core';

@Component({
  selector: 'app-good-footer',
  standalone: true,
  templateUrl: './good.footer.component.html',
  styleUrl: './good.footer.component.css'
})
export class GoodFooterComponent {

}
