import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {HttpClientModule} from '@angular/common/http';
import {OrderService} from '../../service/order.service';
import {SuccessModalComponent} from '../../order-success-modal/success-modal.component';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {OrderComponent} from '../../order/order.component';

@Component({
  selector: 'app-good-order',
  standalone: true, // Markiert die Komponente als Standalone
  imports: [

    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    HttpClientModule,
    MatDialogModule,
  ],


  templateUrl: './good.order.component.html',
  styleUrls: ['./good.order.component.css'],
})
export class GoodOrderComponent extends OrderComponent {


}
