import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private version: string = 'bad'; // Standardmäßig "schlecht"

  constructor(private http: HttpClient) {}

  // Profil vom Backend abrufen
  fetchProfile(): Observable<any> {
    return this.http.get('/api/profile').pipe(
      tap((response: any) => {
        this.version = response.version; // Version (good/bad) speichern
      })
    );
  }
}
